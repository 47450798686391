<template>
  <b-card>

    <!-- media -->
    <b-media no-body style="display:none">
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded :src="userData.avatar" height="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()">
          Upload
        </b-button>
        <b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain
          @input="inputImageRenderer" />
        <!--/ upload button -->

        <!-- reset -->
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm" class="mb-75 mr-75">
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Name" label-for="account-username">
            <b-form-input v-model="userData.name" placeholder="Username" name="username" />
          </b-form-group>
        </b-col>
        <!-- <b-col sm="6">
          <b-form-group label="Name" label-for="account-name">
            <b-form-input v-model="userData.name" name="name" placeholder="Name" />
          </b-form-group>
        </b-col> -->
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input v-model="userData.email" name="email" placeholder="Email" />

          </b-form-group>
        </b-col>

        <!-- alert -->
        <!-- <b-col cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                Resend confirmation
              </b-link>
            </div>
          </b-alert>
        </b-col> -->
        <!--/ alert -->

        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1"
            @click="updateUser()">
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue';

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      userData: {},
      profileFile: null,
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      this.userData = JSON.parse(localStorage.getItem("userData"));

    },
    async resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    async updateUser() {
      let userData = this.userData;
      userData.userType = 'userDashboard';

      await this.$axios.post(`${this.$API_URL_USER}/updateUser`, {
        data: userData
      }).then(success => {
        let user = success.data.response;
        localStorage.setItem("userData", JSON.stringify(user));

        if (success.data.success) {
          Vue.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `User has been update successfully!`,
            },
          });
        } else {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(err => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })

      }
      )
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
