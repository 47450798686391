<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- notification -->
    <!-- <b-tab>
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification v-if="options.notification" :notification-data="options.notification" />
    </b-tab> -->

    <b-tab>
      <template #title>
        <feather-icon icon="RssIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Subscriptions</span>
      </template>
      <account-setting-subscriptions
        v-if="options.subscriptions"
        :notification-data="options.subscriptions"
      />
    </b-tab>
    <!-- <b-tab>
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Invoices</span>
      </template>
    </b-tab> -->
    <b-tab>
      <template #title>
        <feather-icon icon="ClipboardIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Plans</span>
      </template>
      <account-setting-plans
        v-if="options.plans"
        :notification-data="options.plans"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="UserIcon"
          style="color: red"
          size="18"
          class="mr-50"
        />
        <span
          class="font-weight-bold"
          style="color: red"
          @click="deactivateAccount()"
          >Deactivate Account</span
        >
      </template>
    </b-tab>
    <!-- <b-tab>
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Add Ons</span>
      </template>
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AccountSettingGeneral from './AccountSettingGeneral.vue';
import AccountSettingPassword from './AccountSettingPassword.vue';
import AccountSettingInformation from './AccountSettingInformation.vue';
import AccountSettingSocial from './AccountSettingSocial.vue';
import AccountSettingNotification from './AccountSettingNotification.vue';
import AccountSettingSubscriptions from './AccountSettingSubscriptions.vue';
import AccountSettingPlans from './AccountSettingPlans.vue';
import router from '@/router';

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
    AccountSettingSubscriptions,
    AccountSettingPlans,
  },
  data() {
    return {
      options: {},
    };
  },
  mounted() {
    this.fetchSetting().then((data) => {
      this.options = data.accountSetting;
    });

    console.log(this.publicPath);
  },
  beforeCreate() {},
  methods: {
    async fetchSetting() {
      return new Promise(function (resolve, reject) {
        const data = {
          accountSetting: {
            general: {
              avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
              username: 'johndoe',
              fullName: 'John Doe',
              email: 'granger007@hogward.com',
              company: 'Crystal Technologies',
            },
            info: {
              bio: '',
              dob: null,
              country: 'USA',
              website: '',
              phone: 6562542568,
            },
            social: {
              socialLinks: {
                twitter: 'https://www.twitter.com',
                facebook: '',
                google: '',
                linkedIn: 'https://www.linkedin.com',
                instagram: '',
                quora: '',
              },
              connections: {
                twitter: {
                  profileImg: require('@/assets/images/avatars/11-small.png'),
                  id: 'johndoe',
                },
                google: {
                  profileImg: require('@/assets/images/avatars/3-small.png'),
                  id: 'luraweber',
                },
                facebook: {},
                github: {},
              },
            },
            notification: {
              commentOnArticle: true,
              AnswerOnForm: true,
              followMe: false,
              newAnnouncements: true,
              productUpdates: true,
              blogDigest: false,
            },
            subscriptions: {
              avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
            },
            invoices: {
              avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
            },
            plans: {
              avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
            },
            addons: {
              avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
            },
          },
        };

        resolve(data);
      });
    },

    async deactivateAccount() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to deactivate your account ?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const res = await this.$axios.get(
              `${this.$API_URL_USER}/deactivateUserAccount`
            );

            if (res.data.success) {
              // Remove userData from localStorage
              localStorage.removeItem('userData');
              //Remove Admin Switch
              localStorage.removeItem('isAdminSwitch');
              router.go('/login');
              return;
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              });
            }
          }
        });
    },
  },
};
</script>
