<template>
  <b-row>
    <b-col lg="12">
      <div>
        <button class="btn btn-primary upgrade-btn">
          <a href="https://remap.ai">Upgrade</a>
        </button>
      </div>
      <section v-if="planName" id="pricing-plan">
        <!-- pricing plan cards -->
        <b-row class="pricing-card">
          <b-col sm="10" md="12" lg="12" class="mx-auto">
            <b-row v-if="planName == 'Starter'">
              <b-col lg="4">
                <b-card class="popular" align="center">
                  <!-- img -->
                  <!-- <b-img v-if="plan.meta" :src="plan.meta.img" class="mb-2 mt-5"
                                        alt="basic svg img" /> -->
                  <b-img
                    :src="'/img/Pot1.f497783b.svg'"
                    class="mb-2 mt-5"
                    alt="basic svg img"
                  />
                  <!--/ img -->
                  <h3>{{ planName }}</h3>
                  <!-- <b-card-text style="text-align: center; font-size: 12px" v-if="plan.meta">
                                        {{ plan.meta.subtitle }}
                                    </b-card-text> -->

                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 font-weight-bold text-primary"
                        >$</sup
                      >
                      <span
                        class="pricing-basic-value font-weight-bolder text-primary"
                        >29.90</span
                      >
                      <sub
                        class="pricing-duration text-body font-medium-1 font-weight-bold"
                        >/month</sub
                      >
                    </div>
                  </div>
                  <!--/ annual plan -->

                  <!-- plan benefit -->
                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item> Live Listings </b-list-group-item>
                    <b-list-group-item>
                      Basic Search Filters
                    </b-list-group-item>
                    <b-list-group-item>
                      Basic Cover (1 Area)
                    </b-list-group-item>
                    <b-list-group-item> Off-Market Listings </b-list-group-item>
                    <b-list-group-item> AI Pricing (10) </b-list-group-item>
                    <b-list-group-item> Basic Property Data </b-list-group-item>
                    <b-list-group-item> Direct to Agent </b-list-group-item>
                    <b-list-group-item> Connections (30) </b-list-group-item>
                  </b-list-group>
                  <!--/ plan benefit -->
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--/ pricing plan cards -->
      </section>
    </b-col>
  </b-row>
</template>

<script>
import Helper from '../../../helper/helper';
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BListGroupItem,
  BImg,
  BListGroup,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue';
import { ref, watch, computed } from '@vue/composition-api';
import vSelect from 'vue-select';
import { TabContent } from 'vue-form-wizard';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroupItem,
    BImg,
    BListGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    vSelect,
    TabContent,
  },
  data() {
    return {
      userData: {},
      plan: {},
      planName: '',
      tableColumns: [],
      monthlyPlanShow: true,
    };
  },
  mounted() {
    this.userData = Helper.fetchUserData();
    this.planName = this.userData.planname;
    console.log(this.userData);
    // this.fetchPlanByUserID();
  },
  methods: {
    // async fetchPlanByUserID() {
    //   let userID = this.userData.id;
    //   const res = await this.$axios.get(
    //     `${this.$API_URL_PLANS}/fetchPlanByUserID/${userID}`
    //   );
    //   let response = {};
    //   if (res.data.code == 200) {
    //     response = res.data.response[0];
    //   }
    //   return (this.plan = response);
    // },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
// @import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 2600px) and (-webkit-min-device-pixel-ratio: 1) {
  .register-form {
    margin-left: 10rem;
    margin-right: 10rem;
    align-items: center;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 2600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .register-form {
    margin-left: 10rem;
    margin-right: 10rem;
    align-items: center;
  }
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1000px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
  .register-form {
    margin-left: 5rem;
    margin-right: 5rem;
    align-items: center;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1000px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .register-form {
    margin-left: 5rem;
    margin-right: 5rem;
    align-items: center;
  }
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 300px) and (max-device-width: 1000px) and (-webkit-min-device-pixel-ratio: 1) {
  .register-form {
    margin-left: 1rem;
    margin-right: 1rem;
    align-items: center;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 300px) and (max-device-width: 1000px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .register-form {
    margin-left: 1rem;
    margin-right: 1rem;
    align-items: center;
  }
}

.stripeError {
  color: red;
  font-style: italic;
}

.card-body {
  min-height: 475px !important;
}

.upgrade-btn {
  position: relative;
  float: right;
}

.upgrade-btn > a {
  color: #ffff;
}

[dir='ltr'] .vertical.wizard-vertical.vue-form-wizard .wizard-card-footer {
  margin-left: 261px;
  border-left: 1px solid rgba(34, 41, 47, 0.08);
}
</style>
