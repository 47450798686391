<template>
    <div>

        <b-table ref="refSubscriptionListTable" class="position-relative" :items="subscriptionData" responsive
            :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">

            <template #cell(plan_name)="data">
                <div class="text-nowrap">
                    <feather-icon :icon="'SettingsIcon'" size="18" class="mr-50" :class="`text-primary`" />
                    <span class="align-text-top text-capitalize">{{ data.item.product.name }}</span>
                </div>
            </template>

            <template #cell(start_date)="data">
                <div class="text-nowrap">
                    <feather-icon :icon="'SettingsIcon'" size="18" class="mr-50" :class="`text-primary`" />
                    <span class="align-text-top text-capitalize">{{ formatDate(data.item.current_period_start) }}</span>
                </div>
            </template>

            <template #cell(end_date)="data">
                <div class="text-nowrap">
                    <feather-icon :icon="'SettingsIcon'" size="18" class="mr-50" :class="`text-primary`" />
                    <span class="align-text-top text-capitalize">{{ formatDate(data.item.current_period_end) }}</span>
                </div>
            </template>

            <template #cell(auto_renewal)="data">
                <div class="text-nowrap">

                    <span class="align-text-top text-capitalize" v-if="!data.item.cancel_at_period_end">Active</span>
                    <span class="align-text-top text-capitalize" v-if="data.item.cancel_at_period_end">Not Active</span>
                </div>
            </template>

        </b-table>

    </div>
</template>

<script>
import Helper from "../../../helper/helper"
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect
    },
    data() {
        return {
            userData: {},
            subscriptionData: [],
            tableColumns: [],
        }
    },
    mounted() {

        this.userData = Helper.fetchUserData();
        this.fetchSubscriptionByID();

        this.tableColumns = [
            { key: 'plan_name', label: 'Plan Name', sortable: true },
            { key: 'start_date', label: 'Start Date', sortable: true },
            { key: 'end_date', label: 'End Date', sortable: true },
            { key: 'auto_renewal', label: 'Auto Renewal', sortable: true }
        ];


    },
    methods: {

        async fetchSubscriptionByID() {

            let userID = this.userData.id;
            const res = await this.$axios.get(`${this.$API_URL_SUBSCRIPTIONS}/fetchSubscriptionsByID/${userID}`);

            return this.subscriptionData = res.data.response
        },

        formatDate(value) {
            return Helper.dateFormatUnix(value);
        }

    }
}
</script>